@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    text-shadow: none !important;
  }
  ul {
    page-break-inside: avoid;
  }
  p {
    widows: 4;
    orphans: 2;
  }
  body {
    background: transparent !important;
    border: none !important;
    font-size: 24px !important;
    margin: 0;
  }
  div {
    border: none !important;
    box-shadow: none !important;
  }
  ._ .main .center-part div.d1 {
    clear: both !important;
    float: none !important;
    max-width: 900px !important;
  }
  ._ .product-view {
    padding: 10px 0 !important;
  }
  ._ .product-view div.image {
    min-height: 0 !important;
  }
  ._ .product-view div.image .product-images ul li {
    border: none !important;
    box-shadow: 0 0 4px #CCCCCC !important;
  }
  ._ .product-view .description > div {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0.5cm !important;
    padding: 0 !important;
  }
  ._ div.top,
  ._ div.footer,
  ._ div.main div.d2,
  ._ .social-buttons,
  ._ .cart-form,
  ._ .footer-bottom-text,
  ._ .udu__sub-soc-wrapper,
  ._ .zoomWindowContainer,
  ._ .ZoomContainer,
  ._ .prod-nav-buttons,
  ._ .add-options,
  ._ .zoom,
  ._ .zoom-advanced,
  ._ .grouped-products-wrapper,
  ._ #udu__attr-descr,
  ._ #relatedProductsText,
  ._ .related-products {
    display: none !important;
  }
  :root .wnd__window-container {
    display: none !important;
  }
  :root ._ div {
    background: none !important;
  }
  :root ._ .bg-blue .product-view .pricing-info {
    background: none !important;
  }
  :root ._,
  :root ._ .product-view {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
  }
  :root ._ {
    width: 98% !important;
  }
  :root ._ .main {
    width: 100% !important;
  }
  :root ._ .main .center-part div.d1 {
    width: 100% !important;
  }
  :root body::before {
    display: none !important;
  }
}
